import { Container, Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useLocales from '#/hooks/useLocales';
import SearchBar from '#/components/pages/Consultant/SearchBar';
import Users from '#/components/pages/Consultant/users-table/Users';
import useAuth from '#/hooks/useAuth';
import MainInfoModal from '#/components/shared/MainInfoModal';
import GeneralOverviewButton from '#/components/pages/Consultant/general-overview-button';
import useDebounce from '#/hooks/useDebounce';

export default function ConsultantDashboard() {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const { translate } = useLocales();
  const { user } = useAuth();

  const isAdminOrOwner =
    user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

  const debouncedSearch = useDebounce(search, 500);
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleOpenInfoModal = () => setOpen(true);
  const handleCloseInfoModal = () => setOpen(false);

  useEffect(() => {
    if (user?.first_name === '' && user?.last_name === '') {
      handleOpenInfoModal();
    }
  }, [user]);

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack direction="column" spacing={4} width={1}>
          <Stack
            spacing={3}
            sx={{
              textAlign: 'center',
              mx: {
                xs: 0,
                md: 10,
              },
            }}
          >
            <Typography variant="h2">
              {String(translate('global.consultantDashboard.title'))}
            </Typography>
            <Typography variant="body1">
              {String(translate('global.consultantDashboard.description'))}
            </Typography>
          </Stack>
          {isAdminOrOwner && <GeneralOverviewButton />}
          <SearchBar onSearch={handleSearch} />
          <Users search={debouncedSearch} />
        </Stack>
      </Box>
      <MainInfoModal open={open} onClose={handleCloseInfoModal} />
    </Container>
  );
}
