import { Button, Card, Container, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import React, { useState, useRef } from 'react';
import { deDE } from '@mui/x-data-grid/locales';
import {
  RenderEmail,
  RenderLastLogin,
  RenderOwnedBy,
  RenderRowOptions,
  RenderServicePercentage,
  RenderUserDetails,
} from '#/components/pages/Consultant/users-table/user-table-row';
import EmptyContent from '#/components/shared/EmptyContent';
import {
  invalidatePartnerQueries,
  useGetConsultantUserList,
  useTransferUsers,
} from '#/api/partnerQueries';
import useLocales from '#/hooks/useLocales';
import sortingModel from '#/components/pages/Consultant/users-table/sortingModel';
import ColumnHeaderRender from '#/components/pages/Consultant/users-table/column-header-render';
import Iconify from '#/components/shared/ui/Iconify';
import useBoolean from '#/hooks/useBoolean';
import UsersTransferModal from '#/components/pages/Consultant/users-table/users-transfer-modal';
import UsersTransferForm from '#/components/pages/Consultant/users-table/users-transfer-form';
import ConfirmUnassignment from '#/components/pages/Consultant/users-table/confirm-unassignment';

interface ExtendedParamsProps extends GridRenderCellParams {
  service?: string;
  option1?: VoidFunction;
  option2?: VoidFunction;
}

interface SelectedRow {
  id: number;
  first_name: string;
  last_name: string;
  invited_by?: any;
}

const HIDE_COLUMNS = {
  category: false,
};
const HIDE_COLUMNS_TOGGLABLE = ['category', 'name', ''];

export const SERVICE_OPTIONS = [
  { value: 'full', label: 'Full' },
  { value: 'partial', label: 'Partial' },
  { value: 'none', label: 'None' },
];

type ProgressRangeFilterProps = {
  min_percentage: number | null;
  max_percentage: number | null;
};

type Props = {
  search: string;
};

export default function Users({ search }: Props) {
  const [activeFilterHeader, setActiveFilterHeader] = useState<string | null>(
    null
  );
  const [progressRangeFilter, setProgressRangeFilter] =
    useState<ProgressRangeFilterProps>({
      min_percentage: null,
      max_percentage: null,
    });
  const [confirmUnassignment, setConfirmUnassignment] =
    useState<boolean>(false);
  const [sort_by, setSortBy] = useState<GridSortModel | null>();
  const [page, setPage] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(HIDE_COLUMNS);
  const [selectedRowIds, setSelectedRowIds] = useState<
    SelectedRow[] | undefined
  >([]);

  const { translate } = useLocales();
  const { mutateAsync: removeTransfer } = useTransferUsers();

  const confirmRows = useBoolean();
  const filterRef = useRef<HTMLDivElement | null>(null);

  const handleOpenConfirmUnassignment = () => setConfirmUnassignment(true);
  const handleCloseConfirmUnassignment = () => {
    setConfirmUnassignment(false);
    setSelectedRowIds([]);
  };

  const handleResetFilter = () => {
    setProgressRangeFilter({
      min_percentage: null,
      max_percentage: null,
    });
  };

  const handleShowFilter = (field: string) => {
    setActiveFilterHeader((prev) => (prev === field ? null : field));
  };

  const handleSortBy = () => {
    const requestedSortField = sort_by?.[0]?.field;
    const requestedSortDirection = sort_by?.[0]?.sort ?? 'asc';
    return sortingModel?.find((model) => model.field === requestedSortField)
      ?.sort_by[requestedSortDirection];
  };

  const { data: userList, isLoading } = useGetConsultantUserList(
    page + 1,
    true,
    search || undefined,
    sort_by ? handleSortBy() : undefined,
    progressRangeFilter.min_percentage || undefined,
    progressRangeFilter.max_percentage || undefined,
    activeFilterHeader || undefined
  );

  const isInvitedByConsultant = selectedRowIds?.some((user) => user.invited_by);

  const handleProgressRangeFilter = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setProgressRangeFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRemoveFromConsulting = async () => {
    await removeTransfer({
      ids: selectedRowIds?.map((user) => user.id) ?? [],
      destination_consultant: null,
    }).then(() => {
      invalidatePartnerQueries.consultantUserList();
      setSelectedRowIds([]);
      handleCloseConfirmUnassignment();
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'category',
      disableColumnMenu: true,
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.category')
      ),
      filterable: false,
    },
    {
      field: 'name',
      disableColumnMenu: true,
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.name')
      ),
      width: 200,
      hideable: false,
      resizable: true,
      renderCell: (params) => <RenderUserDetails params={params} />,
    },
    {
      field: 'email',
      flex: 1,
      disableColumnMenu: true,
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.email')
      ),
      minWidth: 200,
      editable: true,
      resizable: true,
      renderCell: (params) => <RenderEmail params={params} />,
    },
    {
      field: 'invited_by',
      disableColumnMenu: true,
      flex: 1,
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.owned_by')
      ),
      minWidth: 120,
      editable: true,
      resizable: true,
      renderCell: (params) => <RenderOwnedBy params={params} />,
    },
    {
      field: 'last_login',
      disableColumnMenu: true,
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.last_login')
      ),
      width: 150,
      editable: true,
      resizable: true,
      renderCell: (params) => <RenderLastLogin params={params} />,
    },
    {
      field: 'pension_provision',
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.pension_provision')
      ),
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <ColumnHeaderRender
          field="total_percentage"
          headerName={String(
            translate('adminDashboard.usersTable.tableHeads.pension_provision')
          )}
          handleShowFilter={handleShowFilter}
          filterRef={filterRef}
          activeFilterHeader={activeFilterHeader}
          handleProgressRangeFilter={handleProgressRangeFilter}
          handleResetFilter={handleResetFilter}
        />
      ),
      filterable: false,
      width: 220,
      type: 'singleSelect',
      valueOptions: SERVICE_OPTIONS,
      resizable: true,
      renderCell: (params) => (
        <RenderServicePercentage
          params={{
            params: params as ExtendedParamsProps,
            service: 'avg_percentage',
          }}
        />
      ),
    },
    {
      field: 'living_will',
      disableColumnMenu: true,
      sortable: false,
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.living_will')
      ),
      renderHeader: () => (
        <ColumnHeaderRender
          field="living_will_percentage"
          headerName={String(
            translate('adminDashboard.usersTable.tableHeads.living_will')
          )}
          handleShowFilter={handleShowFilter}
          filterRef={filterRef}
          activeFilterHeader={activeFilterHeader}
          handleProgressRangeFilter={handleProgressRangeFilter}
          handleResetFilter={handleResetFilter}
        />
      ),
      width: 220,
      type: 'singleSelect',
      valueOptions: SERVICE_OPTIONS,
      resizable: true,
      renderCell: (params) => (
        <RenderServicePercentage
          params={{
            params: params as ExtendedParamsProps,
            service: 'living_will',
          }}
        />
      ),
    },
    {
      field: 'power_of_attorney',
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.power_of_attorney')
      ),
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <ColumnHeaderRender
          field="power_of_attorney_percentage"
          headerName={String(
            translate('adminDashboard.usersTable.tableHeads.power_of_attorney')
          )}
          handleShowFilter={handleShowFilter}
          filterRef={filterRef}
          activeFilterHeader={activeFilterHeader}
          handleProgressRangeFilter={handleProgressRangeFilter}
          handleResetFilter={handleResetFilter}
        />
      ),
      width: 220,
      type: 'singleSelect',
      valueOptions: SERVICE_OPTIONS,
      resizable: true,
      renderCell: (params) => (
        <RenderServicePercentage
          params={{
            params: params as ExtendedParamsProps,
            service: 'power_of_attorney',
          }}
        />
      ),
    },
    {
      field: 'last_will',
      headerName: String(
        translate('adminDashboard.usersTable.tableHeads.last_will')
      ),
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <ColumnHeaderRender
          field="last_will_percentage"
          headerName={String(
            translate('adminDashboard.usersTable.tableHeads.last_will')
          )}
          handleShowFilter={handleShowFilter}
          filterRef={filterRef}
          activeFilterHeader={activeFilterHeader}
          handleProgressRangeFilter={handleProgressRangeFilter}
          handleResetFilter={handleResetFilter}
        />
      ),
      minWidth: 220,
      type: 'singleSelect',
      valueOptions: SERVICE_OPTIONS,
      resizable: true,
      renderCell: (params) => (
        <RenderServicePercentage
          params={{
            params,
            service: 'last_will',
          }}
        />
      ),
    },
    {
      field: '',
      sortable: false,
      headerName: 'Options',
      headerClassName: 'options-column-header',
      width: 50,
      hideable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => <RenderRowOptions params={params} />,
    },
  ];

  const getTogglableColumns = () =>
    columns
      .filter((column) => !HIDE_COLUMNS_TOGGLABLE.includes(column.field))
      .map((column) => column.field);

  const isEmpty = !userList?.results?.length;

  return (
    <Container maxWidth="lg">
      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <DataGrid
          checkboxSelection
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              lineHeight: '1.5',
              boxSizing: 'border-box',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '400',
            },
            '& .MuiDataGrid-virtualScroller': {
              ...(isEmpty && {
                height: 'calc(100vh - 600px)',
                marginTop: '50px',
              }),
            },
            '& .MuiDataGrid-cell:last-child': {
              position: 'sticky',
              right: 0,
              backgroundColor: '#fff',
              zIndex: 1,
            },
            '& .options-column-header .MuiDataGrid-columnHeaderTitle': {
              visibility: 'hidden', // Hide the header title for the "Options" column
            },
          }}
          rowCount={userList?.count || 0}
          isCellEditable={() => false}
          pagination
          autoHeight
          loading={isLoading}
          onPaginationModelChange={(newPage) => {
            setPage(newPage.page);
          }}
          disableRowSelectionOnClick
          rows={userList?.results || []}
          columns={columns}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
                page,
              },
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          rowSelectionModel={selectedRowIds?.map((user) => user.id) || []}
          onRowSelectionModelChange={(newSelectionModel) => {
            const selectedUsers = userList?.results.filter((user) =>
              newSelectionModel.includes(user.id)
            );
            setSelectedRowIds(
              selectedUsers?.map((user) => ({
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                invited_by: user.invited_by,
              }))
            );
          }}
          slots={{
            toolbar: () => (
              <GridToolbarContainer
                sx={{
                  backgroundColor: '#F3F6F8',
                }}
              >
                <Stack
                  spacing={1}
                  flexGrow={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{
                    '& .MuiButton-root': {
                      fontWeight: 'normal',
                      color: '#111',
                    },
                  }}
                >
                  {isInvitedByConsultant && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleOpenConfirmUnassignment}
                      startIcon={
                        <Iconify icon="humbleicons:user-remove" color="white" />
                      }
                    >
                      <Typography variant="body2" color="white">
                        {String(
                          translate(
                            'adminDashboard.usersTable.toolbarButtons.removeFromConsulting'
                          )
                        )}
                      </Typography>
                    </Button>
                  )}
                  {!!selectedRowIds?.length && (
                    <Button
                      variant="contained"
                      size="small"
                      color="warning"
                      startIcon={<Iconify icon="tabler:transfer" />}
                      onClick={confirmRows.onTrue}
                    >
                      <Typography variant="body2">
                        {String(
                          translate(
                            'adminDashboard.usersTable.toolbarButtons.transferSelectedUsers',
                            {
                              selectedUsers: selectedRowIds?.length,
                            }
                          )
                        )}
                      </Typography>
                    </Button>
                  )}
                  <GridToolbarColumnsButton lang="de" />
                </Stack>
              </GridToolbarContainer>
            ),
            noRowsOverlay: () => <EmptyContent title="No Data" />,
            noResultsOverlay: () => <EmptyContent title="No results found" />,
          }}
          slotProps={{
            columnsPanel: {
              getTogglableColumns,
            },
          }}
          onSortModelChange={(model) => {
            setSortBy(model);
          }}
        />
      </Card>
      <UsersTransferModal
        open={confirmRows.value}
        onClose={confirmRows.onFalse}
        content={
          <UsersTransferForm
            selectedUsers={selectedRowIds}
            onClose={() => {
              confirmRows.onFalse();
              setSelectedRowIds([]);
            }}
          />
        }
      />
      <ConfirmUnassignment
        open={confirmUnassignment}
        onClose={handleCloseConfirmUnassignment}
        onConfirm={handleRemoveFromConsulting}
      />
    </Container>
  );
}
