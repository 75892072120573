import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-hot-toast';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import {
  invalidatePartnerQueries,
  useCancelUserInvitation,
  useDeleteUser,
  useResendUserInvitation,
} from '#/api/partnerQueries';
import DeleteConfirmationModal from '#/components/pages/Consultant/users-table/delete-confirmation-modal';
import ReportDeathModal from '#/components/shared/ReportDeathModal';

type PopoverProps = {
  open: HTMLElement | null;
  onClose: VoidFunction;
  user_id: string;
  name: string;
};

export const UnconfirmedUser: FC<PopoverProps> = ({
  open,
  onClose,
  user_id,
  name,
}) => {
  const { translate } = useLocales();

  const { mutateAsync: resendInvitation, isLoading: isResending } =
    useResendUserInvitation();
  const { mutateAsync: cancelInvitation, isLoading: isCanceling } =
    useCancelUserInvitation();

  const handleResendInvitation = async () => {
    try {
      await resendInvitation({ id: user_id }).then(() => {
        toast.success(
          String(translate('toast_notifications.success.resend_invitation'))
        );
        invalidatePartnerQueries.consultantUserList();
        onClose();
      });
    } catch (error) {
      toast.error(
        String(translate('toast_notifications.error.resend_invitation'))
      );
    }
  };

  const handleCancelInvitation = async () => {
    try {
      await cancelInvitation({ id: user_id }).then(() => {
        toast.success(
          String(translate('toast_notifications.success.cancel_invitation'))
        );
        invalidatePartnerQueries.consultantUserList();
        onClose();
      });
    } catch (error) {
      console.error(error);
      toast.error(
        String(translate('toast_notifications.error.cancel_invitation'))
      );
    }
  };

  return (
    <CustomPopover
      open={open}
      onClose={onClose}
      arrow="right-top"
      sx={{ width: 'auto' }}
    >
      <MenuItem onClick={handleResendInvitation} sx={{ color: 'text.primary' }}>
        <Iconify
          icon={isResending ? 'gg:spinner-two' : 'fluent:send-16-filled'}
          color={(theme) => theme.palette.primary.main}
        />
        <Typography variant="body2">
          {String(translate('global.team.resendInvitation'))}
        </Typography>
      </MenuItem>
      <MenuItem sx={{ color: 'text.primary' }} onClick={handleCancelInvitation}>
        <Iconify
          icon={
            isCanceling ? 'gg:spinner-two' : 'material-symbols:cancel-outline'
          }
          color={(theme) => theme.palette.error.main}
          sx={{
            animation: isCanceling ? 'spin 1s linear infinite' : 'none',
          }}
        />
        <Typography variant="body2">
          {String(translate('global.team.cancelInvitation'))}
        </Typography>
      </MenuItem>
    </CustomPopover>
  );
};

export const ConfirmedUser: FC<PopoverProps> = ({
  open,
  onClose,
  user_id,
  name,
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [reportDeathOpen, setReportDeathOpen] = useState(false);

  const handleOpenConfirmDelete = () => setConfirmDeleteOpen(true);
  const handleCloseConfirmDelete = () => setConfirmDeleteOpen(false);

  const handleOpenReportDeath = () => setReportDeathOpen(true);
  const handleCloseReportDeath = () => setReportDeathOpen(false);

  const { translate } = useLocales();

  const { mutateAsync: deleteUser, isLoading: isDeleting } = useDeleteUser();

  const handleDeleteUser = async () => {
    try {
      await deleteUser({ user_id }).then(() => {
        toast.success(
          String(translate('toast_notifications.success.deleteUser'))
        );
        onClose();
        invalidatePartnerQueries.consultantUserList();
      });
    } catch (error) {
      toast.error(String(translate('toast_notifications.error.deleteUser')));
    }
  };

  return (
    <CustomPopover
      open={open}
      onClose={onClose}
      arrow="right-top"
      sx={{ width: 'auto' }}
    >
      <MenuItem
        onClick={() => {
          handleOpenReportDeath();
        }}
        sx={{ color: 'text.primary' }}
      >
        <Iconify
          icon="f7:exclamationmark-octagon"
          color={(theme) => theme.palette.primary.main}
        />
        <Typography variant="body2">
          {String(translate('guest.reportDeath'))}
        </Typography>
      </MenuItem>
      <MenuItem
        onClick={handleOpenConfirmDelete}
        sx={{ color: 'text.primary' }}
      >
        <Iconify
          icon={
            isDeleting ? 'gg:spinner-two' : 'material-symbols:cancel-outline'
          }
          color={(theme) => theme.palette.error.main}
          sx={{
            animation: isDeleting ? 'spin 1s linear infinite' : 'none',
          }}
        />
        <Typography variant="body2">
          {String(translate('adminDashboard.deleteUser'))}
        </Typography>
      </MenuItem>
      <ReportDeathModal
        open={reportDeathOpen}
        onClose={handleCloseReportDeath}
        reportUserName={name}
        reportUserId={user_id}
      />
      <DeleteConfirmationModal
        open={confirmDeleteOpen}
        closeDialog={handleCloseConfirmDelete}
        onConfirm={handleDeleteUser}
        userInfo={{ name }}
      />
    </CustomPopover>
  );
};
